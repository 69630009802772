var HtmlToReact = require('html-to-react');
import React from 'react';
import { Imagen, ImgDefault404 } from '../../../Imagen/Imagen';
import { IBody, PItemProps } from './PMain';
import { EnhanceText } from '../utils/EnhanceText';
import { LazyView } from '../../LazyView';
var HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

export type PStringHTMLProps = PItemProps<string, {fancyboxId?: string, lazyImages?: boolean}>
export default ({content, fancyboxId, lazyImages}:PStringHTMLProps)=>{
    const _this = this;
    var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
    var isValidNode = function () {
      return true;
    };
    var processingInstructions = [
        {
            shouldProcessNode: function (node) {
                return node.name === 'iframe';
            },
            processNode: function (node, children, index) {
                const ratio = node.attribs.height/node.attribs.width;
                delete node.attribs.width;
                delete node.attribs.height;
                delete node.attribs.style;
                const iframe = React.createElement('iframe', {className:"aspectratio-child", ...node.attribs});
                const div = React.createElement('div', {key: index, className:"aspectratio", style:{paddingTop:`${ratio*100}%`}} ,iframe);
                return div;
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.type === 'text' && (!node.parent || node.parent.name!== "a");
            },
            processNode: function (node, children, index) {
                return EnhanceText(node.data);
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.type === 'tag' && node.name === "img";
            },
            processNode: function (node, children, index) {
                const _attribs = {
                    width: node.attribs.width,
                    className: node.attribs.class,
                    height: node.attribs.height,
                    src: node.attribs.src,
                    alt: node.attribs.alt,
                };
                let image = <Imagen pId='2x7DsuOkLS' key={index} pDefault={ImgDefault404.Banner} {..._attribs} className='bg-black/20'/>

                if(lazyImages) image = <LazyView id={_attribs.src} observer={{rootMargin: "1000px 0px 1000px 0px", triggerOnce: true}}>
                    {image}
                </LazyView>;

                if(fancyboxId) image = (
                    <a key={index} data-fancybox={`img-${fancyboxId}`} href={node.attribs.src}>
                        {image}
                    </a>
                );
                return image;
            }
        },
        { 
            shouldProcessNode: function (node) {
                return true;
            },
            processNode: processNodeDefinitions.processDefaultNode,
        },
    ];
    var reactElement = htmlToReactParser.parseWithInstructions(content, isValidNode, processingInstructions);

    return (
        <>
            {reactElement}
        </>
    ); 
}