import { useEffect, useState } from "react"

export const useLocaleState = <T extends any=any>(name:string, value?:T)=>{
    const state =  useState<T>(()=> {
        const oldValue = localStorage.getItem(name)
        if(oldValue) return JSON.parse(oldValue).value
        return value
    })
    useEffect(()=>{
        if(state[0] == undefined) localStorage.removeItem(name)
        else localStorage.setItem(name, JSON.stringify({value: state[0]}))
    },[state[0]])
    return state
}