import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import type { UserService } from "../../../../../node_ts/src/services/user/user.service";
import FavIcon from "../../utils/favico";
import { refEnv, useEnv } from "../EnvironmentContext";
import { useSocket } from "../SocketContext";
import { useRefer } from "../../hooks/useRefer";
import { refPeluchan } from "./index";
import { useForceUpdate } from "../../components/utils/useForceUpdate";
import { workerClearCache } from "../../utils/ServiceWorker/utils";
import TagManager from "react-gtm-module";

//@ts-ignore
var MyFavIcon = new FavIcon({
    position: "up",
    animation: "none",
    bgColor: "#dd2c00",
    textColor: "#fff0e2",
});

const IfSoundPlay = (last_noti_time = null) => {
    if (last_noti_time === null) return false;
    if (!localStorage) return true;
    if (localStorage.last_noti != last_noti_time) {
        localStorage.last_noti = last_noti_time;
        return true;
    }
    return false;
};

(window as any).test = IfSoundPlay;


// function useForceRender() {
//   const [c, _c] = useState(0);
//   return () => _c(c + 1);
// }

// function validateTimestampBeforeExecution(timestamp: number, func: () => void) {
//   const lastExecutedTimestamp = parseInt(localStorage.getItem("lastExecutedTimestamp") || "0");

//   if (!lastExecutedTimestamp || timestamp != lastExecutedTimestamp) {
//     localStorage.setItem("lastExecutedTimestamp", timestamp.toString());
//     func();
//   }
// }

const threshold = 3000;
function validateTimeBeforeExecution(func: () => void) {
    const lastExecutionTime = parseInt(
        localStorage.getItem("lastExecutionTime") || "0"
    );
    const currentTime = new Date().getTime();

    if (currentTime >= lastExecutionTime + threshold) {
        localStorage.setItem("lastExecutionTime", currentTime + "");
        func();
    }
}

export type ValidType = Awaited<ReturnType<UserService["Valid"]>>;

export function useValid() {
    const { setEnvironment, env } = useEnv();

    const {forceUpdate} = useForceUpdate()
    useEffect(()=>{
        if(location.host != "localhost") return
        const time = setInterval(forceUpdate, 1000)
        return ()=> clearInterval(time)
    }, [])

    const [{ isToken }] = useCookies();
    const [status, setState] = useState< "ok" | "out">(env?.valid?.status=="ok"? "ok":"out");
    const [statusSocket, setStateSocket] = useState<"disabled"|"ok"|"waiting_retry">()
    const [valid, setValid] = useState<ValidType>(env?.valid?.status=="ok"?env.valid?.data:undefined);
    const [error, setError] = useState();

    const [notisCount, setNotisCount] = useState((env as any)?.valid.data?.user?.notis||0);

    const { socket } = useSocket();

    const user = valid?.user;

    const refer = useRefer({
        setEnvironment, 
        user,
        setValid,
        isToken,
        valid,
        setError,
        setState,
        setNotisCount,
        notisCount,
    });

    const clearLogin = () => {
        setState("out");
        MyFavIcon.badge(0);
        setValid(undefined);
        setError(undefined);
    };

    const validate = async () => {
        socket.on("disconnect", () => {
            setStateSocket("waiting_retry")
        });
        socket.on("connect", () => {
            setStateSocket("ok")
        });

        socket.on("new notification", ({ count, preview, timestamp }) => {
            validateTimeBeforeExecution(() =>
                refPeluchan().sound.notification()
            );
            MyFavIcon.badge(count || 0);
            refer.current.notisCount !== count &&
                refer.current.setNotisCount(count);
            preview.map((p) =>
                refEnv().notify.create("notify", {
                    variant: "notify",
                    preview: p,
                    autoHideDuration: 4000,
                } as any)
            );
        });

        socket.on("new banner message", (message) => {
            (window as any).forceUpdate.oawngowañngknañwkgo(
                message ||
                    "Se realizaron cambios en la pagina, por favor actualiza esta pestaña para evitar problemas."
            );
        });

        socket.on("new server message", (message, classname) => {
            window.log(message, classname);
        });

        socket.on("new environment", (nodeEnv) => {
            refer.current?.setEnvironment(nodeEnv);
        });

        socket.on("reload", (message, timeout = 1000) => {
            if(message.length > 0) window.log(message)
            setTimeout(()=>location.reload(), timeout)
        });

        socket.on("update user state", (value) => {
            if (value.isLogin == false) return clearLogin();

            if (JSON.stringify(refer.current?.valid) !== JSON.stringify(value.valid)) {
                refer.current?.setValid(value.valid);
                refer.current?.setError(undefined);
                refer.current?.setState("ok");
            }
        });
        
    };

    useEffect(()=>{
        if (socket) socket.on("delete cache client", (newVersion) => {
            if(newVersion !== window.version) workerClearCache()
        });
    },[socket])

    useEffect(() => {
        if (!socket) return;
        validate();
    }, [socket]);

    useEffect(()=> MyFavIcon.badge(notisCount),[notisCount])

    // useEffect(()=>{
        // if ("nodeEnv" in window) return;
        //@ts-ignore
        // window.log(`Algo fallo!!`,"error");
        // const lastExecution = localStorage.getItem('lastExecutionTime');
        // const currentTime = new Date().getTime();
        // const oneMinute = 10 * 1000;
    
        // if (!lastExecution || currentTime - parseInt(lastExecution) > oneMinute) {
        //     localStorage.setItem('lastExecutionTime', currentTime.toString());
        //     location.reload();
        // }
    // },[])

    return {
        contexts: {
            login: {
                isToken: isToken === "true",
                isLogin: status === "ok",
                status: status,
                user: user,
                raw: valid,
                error: error,
                errorInternet: error === "Failed to fetch",
                outLogin: async () => {
                    const username = user?.username
                    var {error} = await window.api.outlogin();

                    TagManager.dataLayer({
                        dataLayer: {
                          event: "outlogin",
                          username,
                        }
                    })

                    if(error) return window.log(`La sesión no se cerro correctamente`,"red");
                    return window.log(`Hasta luego`,"purpure");
                },
                rol: {
                    priv: user?.priv || 0,
                    anon: status === "out",
                    normal: (user?.priv || 0) <= 200 || false,
                    mod: (user?.priv || 0) >= 500 || false,
                    dios: (user?.priv || 0) == 1000 || false,
                },
                inLogin: () => {
                    // reconect_socket();
                },
                my: (id = "____") => {
                    return user?._id === id;
                },
                notis: {
                    count: notisCount,
                    toCero: () => {
                        if (notisCount === 0) return;
                        setNotisCount(0);
                        MyFavIcon.badge(0);
                    },
                },
            },
        },
        childrens: () => <></>,
    };
}
