import { useState } from "react";
import { EditorShoutItemType } from "../../editor-shout";
import React from "react";
import { ImageSourceUrl } from "./components/ImageSourceUrl";
import { ImageSourceSelector } from "./components/ImageSourceSelector";
import { ImageSourcePreview } from "./components/ImageSourcePreview";
import { PlaceholderPulse } from "../../../../../../../components/utils/PlaceholderPulse";
import SR from "./../../../../../../../components/utils/resources";

export type DynImageContent = {
    images: {
        url: string,
        width?: number,
        height?: number
    }[]
};

export const DynImage:React.FC<{
    onDelete: ()=> void,
    item: EditorShoutItemType<DynImageContent>,
    setContent: (v: DynImageContent)=> void
    defauld_setting?: {
        native: {
            type: string,
            content: string
        }
    }
}> = ({onDelete, item,setContent, defauld_setting})=>{
        const {content={ images: []}} = item;
        const [mode, setMode] = useState<string | undefined>();
        const [loading, setLoading] = useState<string>()

        const tryAddUrl = (url:string)=>{
            const img = new Image();
            
            // Mensaje mientras valida
            setLoading("Validando imagen");

            img.onload = function() {
                // Si la imagen se carga correctamente
                setLoading(undefined);
                setContent({ images: [...content.images, { url }]})
            };

            img.onerror = function() {
                // Si no se puede cargar la imagen
                setLoading(undefined);
                window.log("Imagen no valida")
            };

            // Asignamos la URL a la imagen para que intente cargarla
            img.src = url;

        }

        if(loading) return <div className="dyn-image mb-3">
            <PlaceholderPulse className="h-10 w-full m flex justify-center items-center">
                <span>
                    {SR._icons._loading_dual_ring}
                </span>
            </PlaceholderPulse>
        </div>

        let CONTENT = null;
        // window.log(JSON.stringify(content.images));
        if(content.images.length === 0){
            if(!mode){
                CONTENT = <ImageSourceSelector
                    onUrl={tryAddUrl}
                    onMode={newMode=> setMode(newMode)}
                    onBack={onDelete}
                />;
            }else{
                if(mode === "url") CONTENT = <ImageSourceUrl 
                    onUrl={tryAddUrl}
                    onBack={()=> setMode(undefined)}
                />;
            }
        }else{
            CONTENT = <ImageSourcePreview onBack={onDelete} content={content}/>
        }

        return <div className="dyn-image">
            {CONTENT}
        </div>;
}