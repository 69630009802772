import React from "react";
import { PickTailwindSizeKeys } from "../utils/tailwindsize"
import cn from "classnames";


export const Label:React.FC<JSX.IntrinsicElements['label']> = ({className,...args})=>{
    return <label className={cn('!text-blue-200 font-sans',InputClass._label,className)} {...args}>
        {" "}{args.children}
    </label>    
}
export const LabelError:React.FC<JSX.IntrinsicElements['div']> = ({className,...args})=>{
    return <div className={`text-bold text-red-300 text-xs mt-1 ${className}`} {...args}/>   
}



const InputClass = {
    _base: cn("transition-all rounded",
    "block px-3 py-[.375rem] focus:outline-0 focus:shadow-shadowForm",
    "dark:bg-[#0a0e17] dark:focus:bg-[#121929]",
    "text-gray-700 dark:text-[#cfe6ff]",
    "border-solid border-[1px] border-gray-300 dark:border-[#0a0e17] focus:border-[#94bbe2] dark:focus:border-[#739ac2]",
    "dark:disabled:opacity-75"),
    _label: "!text-gray-500 dark:text-blue-200",
    _icon: "text-gray-500 dark:text-gray-400",
    default: ""
}
const InputSizeClass:PickTailwindSizeKeys<"xs"|"sm"|"base"|"xl"> =  {
    xs: "h-8",
    sm: "h-9",
    base: "h-10",
    /** awfawf */
    xl: "h-12"
}
const Element:React.FC<JSX.IntrinsicElements['input'] & {htmlId:string, styles?: keyof typeof InputSizeClass, title?: string | JSX.Element }> = ({title, className,htmlId, styles="base", ...args})=>{
    return <>
        {title && <Label className={cn(InputClass._label)} htmlFor={htmlId}>{title}</Label>}
        <input type="text" className={cn(InputClass._base, className, InputClass.default, InputSizeClass[styles])} id={htmlId} required {...args}/>
    </>
}

const WitchIcon:React.FC<React.ComponentProps<typeof Element> &{
    materialIcon:string
}> = ({title, className,htmlId, styles="base",materialIcon, ...args})=>{
    return <>
        {title && <Label htmlFor={htmlId}>{title}</Label>}
        <div className="relative">
            <span className={cn("absolute block material-icons text-[14px] top-[50%] left-[10px] translate-y-[-50%]", InputClass._icon)}>{materialIcon}</span>
            <input type="text" className={cn(InputClass._base, className, InputClass.default, InputSizeClass[styles], "pl-[30px]")} id={htmlId} required {...args}/>
        </div>
    </>
}

export const Input = Object.assign(Element, {
    WitchIcon,
    InputSizeClass,
    Label,
    LabelError
})