// Extender el prototipo de String para agregar String.format
if (!String.prototype['format']) {
    String.prototype['format'] = function (...args: any[]): string {
        return this.replace(/{(\d+)}/g, function (match:any, number:number) { 
            return typeof args[number] !== 'undefined'
                ? args[number]
                : match;
        });
    };
}

// global.d.ts
declare global {
    interface String {
        format(...args: any[]): string;
    }

    interface StringConstructor {
        format(template: string, ...args: any[]): string;
    }
}

export {};


// Hacer String.format como método estático
String['format'] = function (template: string, ...args: any[]): string {
    return template.format(...args);
};

// // Ejemplo de uso
// const formattedString = String.format("Hello, {0}! You have {1} new messages.", "Alice", 5);
// console.log(formattedString); // "Hello, Alice! You have 5 new messages."
