import React, { Fragment } from "react";
import { PImg } from "../../../../../../../contexts/EnvironmentContext/useMedia";
import "./index.scss";

export const Event_halloween:React.FC = ()=>{

    return <Fragment>
        <PImg  refId={"caldero"} className={"halloween"} alt="caldero" src="/resources/logos/events/halloween/caldero.png"/>
        <PImg  refId={"calavera"} className={"halloween"} alt="arana2" src="/resources/logos/events/halloween/arana2.webp"/>
        <PImg  refId={"calavera"} className={"halloween"} alt="arana" src="/resources/logos/events/halloween/arana.png"/>
        <PImg  refId={"calabaza"} className={"halloween"} alt="calabaza" src="/resources/logos/events/halloween/calabaza.png"/>
        <PImg  refId={"vela"} className={"halloween"} alt="vela" src="/resources/logos/events/halloween/vela.png"/>
        <PImg  refId={"calavera"} className={"halloween"} alt="calavera" src="/resources/logos/events/halloween/calavera.webp"/>
    </Fragment>
}