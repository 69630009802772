import React from 'react';

interface AjaxProps {
    path: string;
    _key?: string;
    type?: "get"|"get2"|"post";
    data?: any;
    ajax_setting?: any;
    _ok?: (result: any) => void;
    loading?: () => JSX.Element;
    err?: (error: any) => JSX.Element;
    ok: (data: any, shared: any, _this: _ajax_) => JSX.Element;
    ok2?: boolean;
}

interface AjaxState {
    mode: string;
    data: any;
    error: any;
    cound: number;
}

// @ts-ignore
if (window.ajax === undefined) window.ajax = {};

class _ajax_ extends React.Component<AjaxProps, AjaxState> {
    path: string | undefined;
    _key: string | undefined;
    first: boolean | undefined;

    constructor(props: AjaxProps) {
        super(props);
        this.state = {
            mode: "load",
            data: null,
            error: null,
            cound: 0,
        };
        this.path = undefined;
        this._key = undefined;
        this.first = false;
    }

    async reload({ update = true, reset = false }: { update?: boolean; reset?: boolean } = {}) {
        const _cound = update ? this.state.cound + 1 : 0;
        if (reset) this.setState({ mode: "load", data: null, cound: _cound });
        this.path = this.props.path;
        const _ok = this.props._ok ? this.props._ok : () => {};

        if (this.props._key !== undefined) {
            // @ts-ignore
            window.ajax[this.props._key] = this;
            this._key = this.props._key;
        }

        const type = this.props.type ?? "get";

        try {
            let result;
            switch (type) {
                case "post":
                    result = await window.POST(this.props.path, this.props.data, this.props.ajax_setting);
                    break;
                case "get":
                    result = await window.GET(this.props.path, this.props.ajax_setting);
                    break;
                case "get2":
                    result = await window.GET2(this.props.path, this.props.data, this.props.ajax_setting);
                    break;
                default:
                    throw new Error("Unsupported type");
            }
            _ok(result);
            this.setState({ mode: "complete", data: result, cound: _cound });
        } catch (error) {
            this.setState({ mode: "error", error: error, cound: _cound });
        }

        return true;
    }

    async componentDidMount() {
        this.first = true;
        await this.reload();
    }

    async componentDidUpdate(prevProps: AjaxProps) {
        if (this.path !== this.props.path || this._key !== this.props._key) {
            this.setState({ mode: "load", data: null, error: null });
            await this.reload();
        }
    }

    componentWillUnmount() {
        // Cleanup logic if needed
    }

    render() {
        const { loading, err, ok, ok2 = false } = this.props;
        const { mode, data, error } = this.state;
        this.first = false;
        const COk = ok2 as any;

        if (mode === "load") {
            return <>{loading()}</>;
        }
        if (mode === "error") {
            return <>{err(error)}</>;
        }
        if (mode === "complete") {
            if (COk) {
                //@ts-ignore
                return <COk r={data} shared={window.shared} _this={this} />;
            }
            //@ts-ignore
            return <>{ok(data, window.shared, this)}</>;
        }
        return <div>No está bien configurado!</div>;
    }
}

export default _ajax_;
