export const CLOUDFLARE_PROXY_R2 = {
    test: "ts",
    comments: "co",
    posts: "po",
    privates: "po",
    shouts: "po",
    temp: "tm",
    users: "us",
}

function CLOUDFLARE_PROXY_R2_BUILD(url:string) {
    const reg = /r2\:\/\/(\w*)\/(.*)/.exec(url)
    const short = CLOUDFLARE_PROXY_R2[reg[1]]
    return `https://${short}.r2.peluchan.net/${reg[2]}`
}

//https://imagedelivery.net/GKo274lICZsnSFlvHgcgdw/11e0e703-07f7-46ee-cab8-893eb4a1f700/public
export const PImageDecode = (url?:string)=>{
    if(typeof url !== "string") return {
        type: "UNKNOWN",
        omit_media: false,
        url
    }

    if(url.indexOf("https://media.peluchan.net/") == 0) return {
        type: "MEDIA/PROXY",
        omit_media: false,
        url
    }
    if(url.indexOf("https://imagedelivery.net/GKo274lICZsnSFlvHgcgdw/") == 0) return {
        type: "MEDIA/CFIMG",
        omit_media: false,
        url
    }
    if(url.indexOf("r2://") == 0) {

        return {
            type: "MEDIA/R2",
            omit_media: false,
            url: CLOUDFLARE_PROXY_R2_BUILD(url)
        }
    }
    return {
        type: "EXTERNO",
        omit_media: false,
        url
    }
}