import { FC, useRef, useState } from "react"
import { MaterialIcon } from "../../../../../../../../components/styleguide/components/Icon"
import { ClipboardImage } from "../../../../../../../../components/utils/ClipboardImage"
import UploadPhoto from "../../../../../../../../components/utils/UploadPhoto"
import React from "react"
import { PeluchanUpload } from "../../../../../../../../utils/services/PeluchanUpload"
import { Button } from "../../../../../../../../components/styleguide/components/Button"

type ImageSourceSelectorProps = {
    onBack: ()=>void,
    onMode: (name:string)=> void
    onUrl: (url:string)=>void
}
export const ImageSourceSelector: FC<ImageSourceSelectorProps> = (props)=>{
    const refUploadPhoto = useRef<UploadPhoto>();
    const [loading, setLoading] = useState(false)

    const TryUploadPhoto = async ()=> {
        if(!refUploadPhoto.current) return;
        await refUploadPhoto.current?.Upload({ uploading: ()=> setLoading(true) })
        .then((result)=> props.onUrl(result.data.url))
        .finally(()=> setLoading(false))   
    }

    const TryDrod = async (files:any[])=>{
        if(!refUploadPhoto.current) return;
        setLoading(true)
        console.log(files)
        await PeluchanUpload({file:files[0]})
        .then((result)=> props.onUrl(result.data.url))
        .finally(()=> setLoading(false))   
    }
    
    return <>
    <UploadPhoto ref={(e)=>{if(e) refUploadPhoto.current = e}}/>
    <ClipboardImage onDrod={TryDrod} queryHandlePaste=".create-shout-content">
        <div className="toolbal SelectOption flex space-x-1.5 ">
            <Button.withLoading 
                loading={loading} 
                size="size5" 
                type="smallBlue"
                title="Subir imagen"
                className="font-normal"
                onClick={()=> TryUploadPhoto()}
                children={<>
                    <div className="flex justify-center items-center space-x-3">
                        <MaterialIcon children="cloud_upload"/>
                        <span className="leading-none mt-0.5">{`Subir`}</span>
                    </div>
                </>}
            />
            
            <Button 
                size="size5" 
                type="smallBlue"
                title="Usar URL"
                className="font-normal"
                onClick={()=> props.onMode("url")}
                children={<>
                    <div className="flex justify-center items-center space-x-3">
                        <MaterialIcon children="link"/>
                        <span className="leading-none mt-0.5">{`URL`}</span>
                    </div>
                </>}
            />

            {/* <Button 
                size="size5" 
                type="smallBlue"
                title="Pegar imagen"
                className="font-normal"
                onClick={TryByPaste}
                children={<>
                    <div className="flex justify-center items-center space-x-3">
                        <MaterialIcon children="content_paste" className="text-[16px]"/>
                        <span className="leading-none mt-0.5">{`Pegar`}</span>
                    </div>
                </>}
            /> */}


            <div className="flex-grow-1"/>

            <Button.Icon
                icon="close"
                size="size5" 
                type="smallBlue"
                title="Quitar"
                className="font-normal"
                onClick={props.onBack}
            />
        </div>
    </ClipboardImage>
</>
}