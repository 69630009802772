import React, { FC, useState } from "react";
import { Input } from "../../../../../../../../components/styleguide/components/Input";
import { Button } from "../../../../../../../../components/styleguide/components/Button";

type ImageSourceUrlProps = {
    onBack: ()=>void,
    onUrl: (url:string)=>void
}
export const ImageSourceUrl:FC<ImageSourceUrlProps> = (props)=>{
    const [value, setValue] = useState<string | undefined>();
    

    const handleClickPaste = async () => {
        props.onUrl(await navigator.clipboard.readText())
    };
    const handleOnPaste = async (e:React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault()
        props.onUrl(e.clipboardData.getData('text'))
    };

    const handleSend = async () => {
        const text = await navigator.clipboard.readText();
        setValue(text);
    };

    return <div className="mb-3 toolbal SelectOption flex space-x-1.5">
        <Input 
            htmlId="media-url" 
            value={value} 
            onChange={e=> setValue(e.target?.value)}
            type="text"
            className="form-control !w-auto flex-grow-[1]"  
            placeholder="Link de imagen"
            autoFocus
            onPaste={handleOnPaste}
        />
        {value && <Button.Icon 
            icon="add_photo_alternate"
            size="size4"
            type="smallBlue"
            className="h-[40px] min-w-[40px]"
            onClick={handleSend}
        />}
        {!value && <Button.Icon
            icon="content_paste"
            size="size4"
            type="smallBlue"
            className="h-[40px] min-w-[40px]"
            id="button-addon2"
            onClick={handleClickPaste}
        />
        }
        <Button.Icon
            icon="undo"
            size="size4"
            type="smallBlue"
            className="h-[40px] min-w-[40px]"
            id="button-addon2"
            onClick={()=> props.onBack()}
        />
    </div>
}