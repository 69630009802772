import React from "react";
import cn from "classnames";

const xDay = 60*24;
const xHora = 60;

export const TimeLeftSelector:React.FC<React.HTMLAttributes<HTMLDivElement> & {
    /**
     * Cantidad de tiempo selecionada en minutos
     *
     * @type {number}
     */
    value?: number, 
    onChange: (total?:number)=>void,
    max: number,
    min: number,
    showHours?: boolean,
    showMinutes?: boolean
}> = ({
    max= Infinity,
    min=0,
    value=0, 
    onChange=()=>{},
    showHours=true,
    showMinutes=true,
    ...args
})=>{
    const dias = Math.floor(value/xDay);
    const horas = Math.floor((value - dias*xDay)/xHora);
    const minutos = value - (dias*xDay) - (horas*xHora);

    // useEffect(()=> setTotal(value),[value])

    const sendChange= (total:number)=>{
        if(total < min) return onChange(Math.max(0,min))
        if(total > max) return onChange(Math.min(365*xDay, max))
        onChange(total)
    };

    return <div {...args} className={cn(args.className,"flex",)}>
        <select className="custom-select d-block flex-glow-1" value={dias} onChange={v=> sendChange( parseInt(v.target.value) *xDay + horas*xHora + minutos )}>
            {[...new Array(999)].map((_,i)=> i).filter((dias)=>{
                if((dias*xDay + horas*xHora + minutos) > Math.ceil((max/xDay)+1)*xDay) return false;
                if((dias*xDay + horas*xHora + minutos) < Math.floor(min/xDay)*xDay) return false;
                return true
            }).map(v=> <option key={v} value={v}>{v}d</option>)}
        </select>
        {showHours && <select className="custom-select d-block flex-glow-1" value={horas} onChange={v=> sendChange( dias*xDay + parseInt(v.target.value)*xHora + minutos )}>
            {[...new Array(24)].map((_,i)=> i).filter((horas)=>{
                if((dias*xDay + horas*xHora + minutos) > Math.ceil(max/xHora)*xHora) return false;
                if((dias*xDay + horas*xHora + minutos) < Math.floor(min/xHora)*xHora) return false;
                return true
            }).map(v=> <option key={v} value={v}>{v}h</option>)}
        </select>}
        {showMinutes && <select className="custom-select d-block flex-glow-1" value={minutos} onChange={v=> sendChange( dias*xDay + horas*xHora + parseInt(v.target.value) )}>
            {[...new Array(60)].map((_,i)=> i).filter((minutos)=>{
                if((dias*xDay + horas*xHora + minutos) > max) return false;
                if((dias*xDay + horas*xHora + minutos) < min) return false;
                return true
            }).map(v=> <option key={v} value={v}>{v}m</option>)}
        </select>}
        
    </div>
}